import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const Article = ({ src, href, title, description }) => (
  <ArticleWrapper href={href}>
    <ArticleImage src={src} />
    <ArticleContent>
      <ArticleTitle>{title}</ArticleTitle>
      <ArticleDescription>{description}</ArticleDescription>
    </ArticleContent>
  </ArticleWrapper>
)

const ArticleWrapper = styled.a`
  width: 314px;
  border-radius: 17px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  margin: 0 auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  padding: 0;
  border: none;
  &:focus {
    outline: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }

  ${media.greaterThan("medium")`
    margin: 15px;
  `}
`

const ArticleImage = styled.img`
  height: 186px;
  width: 100%;
  border-radius: 17px 17px 0 0;
`

const ArticleContent = styled.section`
  padding: 25px;
`

const ArticleTitle = styled.h3`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`

const ArticleDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin: 0 auto;
`

export default Article
