import React, { useState, useEffect } from "react"
import styled from "styled-components"
import media from "styled-media-query"

import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

const banner = require("../../images/inicio/banner.png")

const staticImages = Array(1).fill({
  src: banner,
  href: "#",
  alt: "",
})

const Gallery = () => {
  const [images, setImages] = useState([])
  const handleOnDragStart = e => e.preventDefault()

  let carrousel = null

  useEffect(() => {
    setImages(
      staticImages.map((img, index) => (
        <GalleryImage
          key={String(index)}
          src={img.src}
          onDragStart={handleOnDragStart}
          alt={img.alt}
        />
      ))
    )
  }, [])

  const renderDots = (item, index) => {
    return (
      <Dot
        key={String(index)}
        onClick={() => carrousel && carrousel.slideTo(index)}
      />
    )
  }

  return (
    <GalleryContainer>
      <AliceCarousel
        mouseTrackingEnabled
        autoPlay
        autoPlayInterval={3000}
        buttonsDisabled
        dotsDisabled
        items={images}
        ref={el => {
          carrousel = el
        }}
        responsive={{
          0: { items: 1 },
          1024: { items: 1 },
        }}
      />
      <GalleryNavigation>{images.map(renderDots)}</GalleryNavigation>
    </GalleryContainer>
  )
}

const GalleryContainer = styled.section`
  position: relative;
`

const GalleryImage = styled.img`
  height: 100px;
  width: 100%;
  object-fit: cover;
  ${media.greaterThan("medium")`
    height: 353.22px;
  `}
`

const GalleryNavigation = styled.nav`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  z-index: 400;
  width: 100%;
`

const Dot = styled.span`
  display: inline-block;
  height: 17px;
  width: 17px;
  border-radius: calc(17px / 2);
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }
`

export default Gallery
