import React, { useState } from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Gallery from "../components/Gallery/Gallery"
import PlanCard from "../components/PlanCard/PlanCard"
import Layout from "../layout/Layout"
import PanelAt3 from "../components/PanelAt3/PanelAt3"
import Article from "../components/Article/Article"
import { STATIC_PLANS } from "../services/plans"
import { handleChangeTextMasked } from "../helpers/inputChanges"
import { Helmet } from "react-helmet"

const path = {
  article_1: require("../images/article/article-1.png"),
  article_2: require("../images/article/article-2.png"),
  article_3: require("../images/article/article-3.png"),
}

const IndexPage = () => {
  const [cpfCnpj, setCpfCnpj] = useState({ value: "" })

  function validaCPFCNPJ() {
    const cpfCnpjValido = /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/
    if (cpfCnpjValido.test(cpfCnpj.value)) {
      window.location.href = `http://minhajnnet.jnnet.com.br/#/segunda-via?id=${cpfCnpj.value}`
    } else {
      alert("CPF ou CNPJ inválido")
    }
  }

  const submit = e => {
    e.preventDefault()

    validaCPFCNPJ()
  }

  return (
    <Layout title="Inicio">
      <Gallery />
      <PlansSection>
        <PlansSectionTitle>Planos especiais para Você</PlansSectionTitle>
        <Row>
          {STATIC_PLANS.map(plan => (
            <PlanCard key={`${plan.regulation}-${plan.value}`} plan={plan} />
          ))}
        </Row>
      </PlansSection>
      <DuplicateSection>
        <form
          method="GET"
          action="https://minhajnnet.jnnet.com.br/#/segunda-via"
          onSubmit={submit}
        >
          <h2>Retire a 2ª via da sua conta</h2>
          <Input
            name="id"
            placeholder="CPF/CNPJ"
            onChange={e =>
              handleChangeTextMasked(e, setCpfCnpj, [
                "999.999.999-99",
                "99.999.999/9999-99",
              ])
            }
            value={cpfCnpj.value}
          />

          <button type="submit">2ª Via</button>
        </form>
      </DuplicateSection>
      <StyledBackgroundAt3Section />

      <StyledBackgroundTipsSection />

      <Helmet>
        <script
          defer
          src="https://chatbot.mundiale.com.br/jnnet/lib.js"
          type="text/javascript"
        />
        <script
          defer
          src="https://chatbot.mundiale.com.br/jnnet/snippet.js"
          type="text/javascript"
        />
      </Helmet>
    </Layout>
  )
}

const BackgroundSection = ({ className, tips }) => (
  <StaticQuery
    query={graphql`
      query {
        minhaAt3: file(relativePath: { eq: "inicio/minha_jnnet@2x.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        tips: file(relativePath: { eq: "inicio/dicas.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const minhaAt3Img = data.minhaAt3.childImageSharp.fluid

      const tipsImg = data.tips.childImageSharp.fluid

      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={tips ? tipsImg : minhaAt3Img}
          backgroundColor="#040e18"
        >
          {tips ? (
            <>
              <TipsSectionTitle>DICAS DA JNNET</TipsSectionTitle>
              <Row>
                <Article
                  src={path.article_1}
                  href="https://www.speedtest.net/pt"
                  title="Teste sua velocidade"
                  description="A JNNET disponibiliza um velocímetro para você."
                />
                <Article
                  src={path.article_2}
                  href="https://www.facebook.com/jnnetinternet"
                  title="Conheça as redes sociais da JNNET"
                  description="Siga-nos para ficar por dentro de tudo que acontece"
                />
              </Row>
            </>
          ) : (
            <PanelAt3 />
          )}
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundAt3Section = styled(BackgroundSection)`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 540px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
`

const PlansSection = styled.section`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`

const PlansSectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: 400;
`

const DuplicateSection = styled.section`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};

  & img {
    height: 70px;
    width: 70px;
    margin-right: 20px;
  }

  & h2 {
    color: #fff !important;
    text-align: left;
    font-size: 23px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-right: 20px;
  }

  & button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
    width: 167px;
    height: 52px;
    margin-left: 15px;
  }

  & form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1080px;
    padding: 1.5rem;
  }
`

const Input = styled.input`
  width: 100%;
  max-width: 344px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

const TipsSectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: bold;
  text-transform: uppercase;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 900px;
`

const StyledBackgroundTipsSection = styled(BackgroundSection).attrs({
  tips: true,
})`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`

export default IndexPage
