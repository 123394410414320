import { mask, unMask } from "remask"

export const handleChangeTextMasked = (event, set, pattern) => {
  const { value } = event.target

  const originalValue = unMask(value)
  const maskedValue = mask(originalValue, pattern)

  set({ value: maskedValue, error: "" })
}

export const handleChangeText = (event, set) => {
  const { value } = event.target

  set({ value, error: "" })
}

export const handleChangeCheckboxes = (event, list, set) => {
  const { value } = event.target
  if (list.includes(value)) {
    const newList =
      list.length > 1 ? list.splice(list.indexOf(value) - 1, 1) : []

    set({ value: newList, error: "" })
  } else {
    set({ value: [...list, value], error: "" })
  }
}

export const handleChangeFile = (event, set) => {
  const {
    files: [file],
  } = event.target
  set({ value: file, error: "" })
}
