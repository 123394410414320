import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const PanelAt3 = () => (
  <Card>
    <div>
      <CardTitle>Minha JNNET</CardTitle>
      <CardContent>
        Nossos clientes podem resolver tudo aqui no nosso site.
        <ul>
          <li>
            <Dot />
            Segunda via
          </li>
          <li>
            <Dot />
            Visita técnica
          </li>
          <li>
            <Dot />
            Atendimento ao cliente
          </li>
          <li>
            <Dot />
            Informe de pagamento
          </li>
        </ul>
        Mas, se preferir, a JNNET te atende pessoalmente: Rua Pedro Nolasco,
        115, Centro, João Neiva - ES.
        <br />
        0800 500 4650
      </CardContent>
    </div>
    <CheckContainer>
      <Check href="https://minhajnnet.jnnet.com.br">Acesse</Check>
    </CheckContainer>
  </Card>
)

const Card = styled.div`
  max-width: 402px;
  max-height: 402px;
  padding: 50px;
  border-radius: 0 100px 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;

  ${media.greaterThan("medium")`
    max-width: 402px;
    max-height: 402px;
  `}
`

const CardTitle = styled.h2`
  font-size: 53px;
  font-weight: bold;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.primary};
`

const Dot = styled.span`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-right: 5px;
`

const CardContent = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primary};
  line-height: 1.3;

  & ul {
    margin: 15px 0;
    & li {
      font-weight: bold;
      margin: 10px 0;
    }
  }
`

const Check = styled.a`
  height: 31px;
  width: 153px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
`

const CheckContainer = styled.div`
  margin-top: 25px;
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
`

export default PanelAt3
